import React from "react";
import { useNavigate } from "react-router-dom";
import {
  MicrophoneIcon,
  HomeIcon,
  UserIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

function RecordHomePageComponent() {
  const navigate = useNavigate();

  const quickActions = [
    {
      icon: MicrophoneIcon,
      title: "Record Child Note",
      description:
        "Create a new voice note for a Child, Observation or General Note.",
      onClick: () => navigate("/record/child"),
    },
    {
      icon: HomeIcon,
      title: "Record Home Note",
      description: "Create a new voice note for a Home, General Note.",
      onClick: () => navigate("/record/home"),
    },
    {
      icon: UserIcon,
      title: "Record Personal Note",
      description: "Create a new voice note for your Personal, General Note.",
      onClick: () => navigate("/record/personal"),
    },
    {
      icon: DocumentTextIcon,
      title: "View All Notes",
      description: "Access and manage all your recorded notes.",
      onClick: () => navigate("/notes"),
    },
  ];

  function QuickAction({ icon: Icon, title, description, onClick }) {
    return (
      <button
        onClick={onClick}
        className="relative group bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
      >
        <div>
          <span className="rounded-lg inline-flex p-3 bg-primary-50 text-primary-700 ring-4 ring-white">
            <Icon className="h-6 w-6" aria-hidden="true" />
          </span>
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-medium text-gray-900 group-hover:text-primary-700">
            {title}
          </h3>
          <p className="mt-2 text-sm text-gray-500">{description}</p>
        </div>
      </button>
    );
  }
  return (
    <div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 pt-4">
        {quickActions.map((action, index) => (
          <QuickAction key={index} {...action} />
        ))}
      </div>
    </div>
  );
}

export default RecordHomePageComponent;
