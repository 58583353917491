import { ChevronDownIcon } from "@heroicons/react/24/outline";

const ExpandIcon = ({ isExpanded }) => (
  <ChevronDownIcon
    className={`h-5 w-5 text-gray-400 transition-transform duration-200 ${
      isExpanded ? "transform rotate-180" : ""
    }`}
  />
);

export default ExpandIcon; 