import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useMsal } from "@azure/msal-react";
import { SettingsSection } from '../../pages/Settings';

const AccountSection = () => {
    const { instance } = useMsal();

    const handleSwitchUser = async () => {
        try {
            // First end the current session
            await instance.logoutRedirect({
                onRedirectNavigate: () => false // Prevents automatic redirect
            }).then(() => {
                // After session ends, initiate new login with select_account prompt
                return instance.loginRedirect({
                    prompt: 'select_account',
                    scopes: ["User.Read"]
                });
            });
        } catch (error) {
            console.error('Error switching user:', error);
        }
    };

    const handleSignOut = async () => {
        try {
            await instance.logoutRedirect({
                postLogoutRedirectUri: window.location.origin
            });
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <SettingsSection title="Account Information" icon={UserCircleIcon}>
            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <p className="mt-1 text-sm text-gray-500">
                        {instance.getAllAccounts()[0]?.username || 'Not available'}
                    </p>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <p className="mt-1 text-sm text-gray-500">
                        {instance.getAllAccounts()[0]?.name || 'Not available'}
                    </p>
                </div>
                <div className="pt-2 flex space-x-4">
                    <button
                        onClick={handleSwitchUser}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        Switch User
                    </button>
                    <button
                        onClick={handleSignOut}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </SettingsSection>
    );
};

export default AccountSection; 