import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { INITIAL_FILTERS } from "../../constants/filterConstants";

const FilterPanel = ({
    onFilterChange,
    availableCategories = [],
    notes = [],
}) => {
    // Initialize isOpen as true for default expanded view
    const [isOpen, setIsOpen] = useState(true);


    const [filters, setFilters] = useState(INITIAL_FILTERS);

    const dateRangeOptions = [
        { value: "all", label: "All Time" },
        { value: "today", label: "Today" },
        { value: "yesterday", label: "Yesterday" },
        { value: "lastWeek", label: "Last Week" },
        { value: "lastMonth", label: "Last Month" },
        { value: "custom", label: "Custom Range" },
    ];

    const recordTypeOptions = [
        { value: "personal", label: "Personal Notes" },
        { value: "child", label: "Child Notes" },
        { value: "home", label: "Home Notes" },
    ];

    // Modify how we create category options
    const getCategoryOptions = () => {
        // Filter categories based on record type
        const relevantCategories = availableCategories[filters.recordType] || [];

        // Only add "All Categories" if we have more than one category
        if (relevantCategories.length > 1) {
            return [
                { value: "all", label: "All Categories" },
                ...relevantCategories.map((category) => ({
                    value: category,
                    label: category
                        .split("_")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" "),
                })),
            ];
        }

        // If we have exactly one category, return just that category
        if (relevantCategories.length === 1) {
            const category = relevantCategories[0];
            return [{
                value: category,
                label: category
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
            }];
        }

        // If no categories available
        return [{ value: "none", label: "No Categories Available" }];
    };

    // Get unique home names and child names from notes
    const uniqueHomeNames = [
        ...new Set(
            notes.filter((note) => note.home_name).map((note) => note.home_name)
        ),
    ];

    const uniqueChildNames = [
        ...new Set(
            notes.filter((note) => note.child_name).map((note) => note.child_name)
        ),
    ];

    // Create entity name options based on record type
    const getEntityNameOptions = () => {
        if (filters.recordType === "home") {
            if (uniqueHomeNames.length === 0) {
                return [{ value: "none", label: "Not Available" }];
            }
            return uniqueHomeNames.map((name) => ({
                value: name,
                label: name,
            }));
        } else if (filters.recordType === "child") {
            if (uniqueChildNames.length === 0) {
                return [{ value: "none", label: "Not Available" }];
            }
            return uniqueChildNames.map((name) => ({
                value: name,
                label: name,
            }));
        }
        return [];
    };

    const handleFilterChange = (key, value) => {
        const newFilters = { ...filters };

        // Special handling for record type changes
        if (key === "recordType") {
            newFilters.recordType = value;
            // Reset entity name when changing record type
            newFilters.entityName = "none";
            // Set category to either the only available category, or 'all' if multiple categories exist
            const relevantCategories = availableCategories[value] || [];
            newFilters.category = relevantCategories.length === 1
                ? relevantCategories[0]
                : (relevantCategories.length > 1 ? 'all' : 'none');
        } else {
            newFilters[key] = value;
        }

        setFilters(newFilters);
        onFilterChange(newFilters);
    };

    const clearFilters = () => {
        setFilters(INITIAL_FILTERS);
        onFilterChange(INITIAL_FILTERS);
    };

    return (
        <div className="mb-4">
            <div className="flex items-center justify-between">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                    <FunnelIcon
                        className={`h-4 w-4 mr-2 ${isOpen ? "text-primary-600" : "text-gray-500"
                            }`}
                    />
                    Filters {isOpen ? "(Hide)" : "(Show)"}
                </button>
                {isOpen && (
                    <button
                        onClick={clearFilters}
                        className="text-sm text-gray-600 hover:text-gray-900"
                    >
                        Clear all
                    </button>
                )}
            </div>

            {isOpen && (
                <div className="mt-4 p-4 bg-white rounded-lg shadow border">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {/* Date Range Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Date Range
                            </label>
                            <select
                                value={filters.dateRange}
                                onChange={(e) =>
                                    handleFilterChange("dateRange", e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            >
                                {dateRangeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            {filters.dateRange === "custom" && (
                                <div className="mt-2 space-y-2">
                                    <DatePicker
                                        selected={filters.customStartDate}
                                        onChange={(date) =>
                                            handleFilterChange("customStartDate", date)
                                        }
                                        selectsStart
                                        startDate={filters.customStartDate}
                                        endDate={filters.customEndDate}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={filters.customEndDate}
                                        onChange={(date) =>
                                            handleFilterChange("customEndDate", date)
                                        }
                                        selectsEnd
                                        startDate={filters.customStartDate}
                                        endDate={filters.customEndDate}
                                        minDate={filters.customStartDate}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                                        placeholderText="End Date"
                                    />
                                </div>
                            )}
                        </div>

                        {/* Record Type Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Record Type
                            </label>
                            <select
                                value={filters.recordType}
                                onChange={(e) =>
                                    handleFilterChange("recordType", e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            >
                                {recordTypeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Entity Name Filter */}
                        {(filters.recordType === "home" ||
                            filters.recordType === "child") && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                        {filters.recordType === "home" ? "Home Name" : "Child Name"}
                                    </label>
                                    <select
                                        value={filters.entityName}
                                        onChange={(e) =>
                                            handleFilterChange("entityName", e.target.value)
                                        }
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                                    >
                                        {getEntityNameOptions().map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                        {/* Category Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Category
                            </label>
                            <select
                                value={filters.category}
                                onChange={(e) => handleFilterChange("category", e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            >
                                {getCategoryOptions().map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Search Term */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Search by User
                            </label>
                            <input
                                type="text"
                                value={filters.searchTerm}
                                onChange={(e) =>
                                    handleFilterChange("searchTerm", e.target.value)
                                }
                                placeholder="Search by username..."
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterPanel;
