import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdditionalNoteText,
  setIsRecording,
  setSelectedCategory,
  setSelectedPersonalCategory,
  setSelectedHomeCategory,
  setSelectedChildCategory,
} from "../store/slices/uiSlice";
import {
  startRecording,
  setCurrentConversationId,
} from "../store/slices/audioSlice";
import { audioService } from "../services/audioService";
import { MicrophoneIcon, StopIcon } from "@heroicons/react/24/solid";
import {
  FolderPlusIcon,
  PencilSquareIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { store } from "../store/store";
import { useAuth } from "../auth/AuthContext";
import { apiClient } from "../api/apiConfig";
import { API_ENDPOINTS } from "../api/apiConfig";

function RecordView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [recordingTime, setRecordingTime] = useState(0);
  const [noteText, setNoteText] = useState("")
  const [isEditing, setIsEditing] = useState(true);
  const [isStoppingRecording, setIsStoppingRecording] = useState(false);
  const [isStartingRecording, setIsStartingRecording] = useState(false);
  const { authToken } = useAuth();
  const [wakeLock, setWakeLock] = useState(null);
  const [promptName, setPromptName] = useState("");
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);

  const isRecording = useSelector((state) => state.ui.isRecording);
  const selectedCategory = useSelector((state) => state.ui.selectedCategory);
  const selectedChildCategory = useSelector(
    (state) => state.ui.selectedChildCategory
  );
  const selectedPersonalCategory = useSelector(
    (state) => state.ui.selectedPersonalCategory
  );
  const selectedHomeCategory = useSelector(
    (state) => state.ui.selectedHomeCategory
  );

  useEffect(() => {
    const category = searchParams.get("category");
    if (category) {
      dispatch(setSelectedCategory(category));
    }

    return () => {
      if (isRecording) {
        const cleanup = async () => {
          const stream = await audioService.getStream();
          if (stream) {
            stream.getTracks().forEach(track => {
              track.stop();
            });
          }
        };
        cleanup();
        handleStopRecording();
      }
    };
  }, [dispatch, searchParams, isRecording]);

  useEffect(() => {
    let interval;
    if (isRecording && !isStoppingRecording) {
      interval = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRecording, isStoppingRecording]);

  useEffect(() => {
    const requestWakeLock = async () => {
      if (isRecording && !wakeLock) {
        try {
          const lock = await navigator.wakeLock.request('screen');
          setWakeLock(lock);
        } catch (err) {
          console.log('Wake Lock error:', err);
        }
      }
    };

    const releaseWakeLock = async () => {
      if (wakeLock) {
        try {
          await wakeLock.release();
          setWakeLock(null);
        } catch (err) {
          console.log('Wake Lock release error:', err);
        }
      }
    };

    if (isRecording) {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }

    // Cleanup function
    return () => {
      releaseWakeLock();
    };
  }, [isRecording, wakeLock]);

  useEffect(() => {
    const fetchPromptName = async () => {
      if (!selectedCategory) return;

      setIsLoadingPrompt(true);
      try {
        const queryParams = new URLSearchParams();
        queryParams.append('record_type', selectedCategory === 'home' ? 'home_note' : selectedCategory === 'personal' ? 'personal_note' : 'child_note');
        queryParams.append('category', selectedChildCategory || selectedPersonalCategory || selectedHomeCategory);

        const response = await apiClient.get(
          `${API_ENDPOINTS.GET_BACKEND_PROMPTS}?${queryParams.toString()}`
        );

        if (response.prompts && response.prompts.length > 0) {
          setPromptName(response.prompts[0].name || "");
        } else {
          setPromptName("");  // Reset prompt name if no prompts found
        }
      } catch (error) {
        console.error('Error fetching prompt:', error);
        setPromptName("");  // Reset prompt name on error
      } finally {
        setIsLoadingPrompt(false);
      }
    };

    fetchPromptName();
  }, [selectedCategory, selectedChildCategory]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleStartRecording = async () => {
    setIsStartingRecording(true);
    try {
      const result = await dispatch(startRecording()).unwrap();
      dispatch(setCurrentConversationId(result.conversation_id));
      const wsUrlWithParams = `${result.websocket_url}?record_id=${result.record_id}&token=${authToken}`;
      const conversationId = result.conversation_id;
      await audioService.setConversationId(conversationId);
      const audioServiceInstance = await audioService.startRecording(
        wsUrlWithParams
      );
      dispatch(setIsRecording(true));
      setRecordingTime(0);
    } catch (error) {
      console.error("Failed to start recording:", error);
    } finally {
      setIsStartingRecording(false);
    }
  };

  const handleStopRecording = async () => {
    const { currentConversationId } = store.getState().audio;
    setIsStoppingRecording(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      await audioService.stopRecording();
      const stream = await audioService.getStream();
      if (stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
      dispatch(setIsRecording(false));
      dispatch(setSelectedCategory(null));
      dispatch(setSelectedChildCategory(null));
      navigate("/notes");
    } catch (error) {
      console.error("Failed to stop recording:", error);
    } finally {
      setIsStoppingRecording(false);
    }
  };

  const handleSaveNote = () => {
    setIsEditing(false);
    dispatch(setAdditionalNoteText(noteText));
  };

  const handleEditNote = () => {
    setIsEditing(true);
  };

  const handleCopyNote = () => {
    navigator.clipboard.writeText(noteText);
  };

  const generalNoteReminders = [
    "Purpose of the note",
    "Key information or updates",
    "Any decisions made",
    "Actions required",
    "Who needs to be informed",
    "Any follow-up needed",
  ];

  const paceReminders = [
    "Use PACE language (Playful, Accepting, Curious, Empathetic)",
    "Note who was present during the interaction",
    "Describe the setting and context",
    "Record any significant behaviors or responses",
  ];

  const homeNoteReminders = [
    "Overall home atmosphere",
    "Group dynamics",
    "Any maintenance issues",
    "Health and safety matters",
    "Staffing updates",
    "Upcoming events or changes",
    "Required actions",
  ];

  const personalNoteReminders = [
    "Any Key reflections",
    "Todo list items",
    "Follow-up required",
    "Concerns",
  ];

  const getReminders = () => {
    if (selectedCategory === "home") {
      return homeNoteReminders;
    } else if (selectedCategory === "personal") {
      return personalNoteReminders;
    }
    return selectedChildCategory === "general"
      ? generalNoteReminders
      : paceReminders;
  };

  if (!selectedCategory) {
    return (
      <div className="text-center">
        <p className="text-gray-500">
          Please select a category from the Record page
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto">
      <div>
        <div className="text-center">
          <div className="mb-6">
            <div className="mb-4">
              <h3 className="text-lg text-left font-medium text-gray-900 mb-2">
                Remember to include:
              </h3>
              <ul className="text-sm text-gray-600 text-left space-y-1">
                {getReminders().map((item, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-2">
              <textarea
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                disabled={!isEditing}
                className={`w-full h-16 p-3 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 ${!isEditing ? "bg-gray-50" : ""
                  }`}
                placeholder="Add details to your Voice Note... Please hit save before you start recording!"
              />
              <div className="flex justify-end space-x-2">
                {isEditing ? (
                  <button
                    onClick={handleSaveNote}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-full hover:bg-green-700"
                  >
                    <FolderPlusIcon className="h-5 w-5 mr-2" />
                    Save
                  </button>
                ) : (
                  <>
                    <button
                      onClick={handleCopyNote}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full hover:bg-gray-200"
                    >
                      <ClipboardDocumentIcon className="h-5 w-5 mr-2" />
                      Copy
                    </button>
                    <button
                      onClick={handleEditNote}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full hover:bg-gray-200"
                    >
                      <PencilSquareIcon className="h-5 w-5 mr-2" />
                      Edit
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="mb-4">
              <span className="text-4xl font-mono text-gray-700">
                {formatTime(recordingTime)}
              </span>
            </div>

            <div className="flex justify-center">
              <button
                onClick={isRecording ? handleStopRecording : handleStartRecording}
                disabled={isStoppingRecording || isStartingRecording || (!isRecording && !promptName)}
                className={`inline-flex items-center px-6 py-3 text-base font-medium rounded-full ${isRecording
                  ? "bg-red-600 hover:bg-red-700 text-white"
                  : "bg-primary-600 hover:bg-primary-700 text-white"
                  } ${(isStoppingRecording || isStartingRecording || (!isRecording && !promptName)) ? "opacity-75 cursor-not-allowed" : ""}`}
              >
                {isRecording ? (
                  <>
                    {isStoppingRecording ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Stopping...
                      </>
                    ) : (
                      <>
                        <StopIcon className="h-6 w-6 mr-2" />
                        Stop Recording
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isStartingRecording ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Starting...
                      </>
                    ) : (
                      <>
                        <MicrophoneIcon className="h-6 w-6 mr-2" />
                        Start Recording
                      </>
                    )}
                  </>
                )}
              </button>
            </div>

            <div className="mt-6">
              {isLoadingPrompt ? (
                <div className="text-lg font-medium text-gray-500 text-center">Loading prompt...</div>
              ) : promptName ? (
                <h3 className="text-lg text-gray-600 mb-4 text-center">
                  <span className="font-medium">Using Backend Prompt:</span>{" "}
                  <span className="font-normal">"{promptName}"</span>
                </h3>
              ) : (
                <div className="text-lg mb-4 text-center">
                  <span className="font-medium text-red-600">
                    You cannot start the recording as backend prompt is not configured
                  </span>
                  <span className="text-gray-600 mx-2">-</span>
                  <Link
                    to="/settings"
                    state={{ section: 'backend-prompts' }}
                    className="text-blue-600 hover:text-blue-700 underline"
                  >
                    Click here to add backend prompt
                  </Link>
                </div>
              )}
            </div>

            {isRecording && !isStoppingRecording && (
              <div className="mt-4 text-lg font-medium text-green-600">
                You Can Commence Recording Now
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecordView;
