import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes } from "../store/slices/notesSlice";
import { fetchAudioUrl } from "../api/apiConfig";
import {
  toggleSelectedNote,
  clearSelectedNotes,
} from "../store/slices/uiSlice";
import {
  DocumentTextIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
  ClipboardDocumentIcon,
  TrashIcon,
  PencilIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { Tab, RadioGroup } from "@headlessui/react";
import { apiClient } from "../api/apiConfig";
import { API_ENDPOINTS } from "../api/apiConfig";
import ExpandIcon from "./common/ExpandIcon";
import FilterPanel from "./common/FilterPanel";
import { INITIAL_FILTERS } from "../constants/filterConstants";

const AudioPlayer = ({ recordId }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setIsLoading(true);
      fetchAudioUrl(recordId)
        .then(setAudioUrl)
        .finally(() => setIsLoading(false));
    }
  }, [recordId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-4">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary-600"></div>
        <span className="ml-2 text-sm text-gray-500">Loading audio...</span>
      </div>
    );
  }

  return (
    <audio
      src={audioUrl}
      controls
      className="w-full"
      controlsList="nodownload"
      onError={(e) => {
        e.target.outerHTML =
          '<p class="text-sm text-gray-500 italic">Audio not available</p>';
      }}
    />
  );
};

const ListItemHeader = ({
  isSelected,
  onCheckboxChange,
  title,
  subtitle,
  timestamp,
  userName,
  customPromptName,
  backendPromptName,
  onDelete,
  isDeleting,
  isExpanded,
}) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center space-x-3">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={(e) => {
          e.stopPropagation();
          onCheckboxChange(e);
        }}
        onClick={(e) => e.stopPropagation()}
        className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
      />
      <div className="flex items-center space-x-3">
        <div className="ml-3">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
          </div>
          {timestamp && (
            <div className="space-y-1">
              <div className="text-sm text-gray-500">
                {timestamp}
              </div>
              <div className="text-sm text-gray-500">
                {userName ? `By: ${userName}` : "By: Not Available"}
              </div>
              <div className="text-sm text-gray-500">
                {title.includes('Summary') ?
                  `Custom Prompt: ${customPromptName || "Not Available"}` :
                  `Backend Prompt: ${backendPromptName || "Not Available"}`}
              </div>
            </div>
          )}
        </div>
        <ExpandIcon isExpanded={isExpanded} />
      </div>
    </div>
    <button
      onClick={(e) => {
        e.stopPropagation();
        onDelete();
      }}
      disabled={isDeleting}
      className="p-1 hover:bg-red-50 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isDeleting ? (
        <svg
          className="animate-spin h-5 w-5 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-600" />
      )}
    </button>
  </div>
);

function NotesView() {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.notes);
  const loading = useSelector((state) => state.notes.loading);
  const selectedNotes = useSelector((state) => state.ui.selectedNotes);
  const [customPrompt, setCustomPrompt] = useState("");
  const [showSummarizeModal, setShowSummarizeModal] = useState(false);
  const [expandedNoteId, setExpandedNoteId] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [summarizedNotes, setSummarizedNotes] = useState([]);
  const [copiedTranscript, setCopiedTranscript] = useState(false);
  const [copiedAdditionalNotes, setCopiedAdditionalNotes] = useState(false);
  const [copiedSummary, setCopiedSummary] = useState(false);
  const [copiedGeneratedSummary, setCopiedGeneratedSummary] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [expandedSummaryId, setExpandedSummaryId] = useState(null);
  const [editingSummary, setEditingSummary] = useState(null);
  const [editedSummaryText, setEditedSummaryText] = useState("");
  const [editingGeneratedSummary, setEditingGeneratedSummary] = useState(null);
  const [editedGeneratedSummaryText, setEditedGeneratedSummaryText] = useState("");
  const [predefinedPrompts, setPredefinedPrompts] = useState([]);
  const [selectedPredefinedPrompts, setSelectedPredefinedPrompts] = useState(
    []
  );
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [selectedRecordDetails, setSelectedRecordDetails] = useState(null);
  const [loadingRecordDetails, setLoadingRecordDetails] = useState(false);
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  const [deletingNoteId, setDeletingNoteId] = useState(null);
  const [selectedSummaries, setSelectedSummaries] = useState([]);
  const [isBulkDeletingSummaries, setIsBulkDeletingSummaries] = useState(false);
  const [deletingSummaryId, setDeletingSummaryId] = useState(null);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filteredSummaries, setFilteredSummaries] = useState([]);

  useEffect(() => {
    // Clear selected notes when component mounts
    dispatch(clearSelectedNotes());
    // Fetch notes
    dispatch(fetchNotes());
  }, [dispatch]);

  useEffect(() => {
    if (notes?.records) {
      setFilteredNotes(notes.records);
    }
  }, [notes]);

  useEffect(() => {
    if (summarizedNotes) {
      setFilteredSummaries(summarizedNotes);
    }
  }, [summarizedNotes]);

  useEffect(() => {
    if (notes?.records) {
      // Apply initial filter for personal notes
      handleFilterChange(INITIAL_FILTERS);
    }
  }, [notes?.records]); // Only run when notes are loaded

  const getCategoriesByRecordType = () => {
    if (!notes?.records) return {};

    return notes.records.reduce((acc, note) => {
      const recordTypeMap = {
        personal_note: 'personal',
        child_note: 'child',
        home_note: 'home'
      };

      const recordType = recordTypeMap[note.record_type];
      if (!acc[recordType]) {
        acc[recordType] = new Set();
      }
      if (note.category) {
        acc[recordType].add(note.category);
      }
      return acc;
    }, {});
  };

  const categoriesByRecordType = Object.entries(getCategoriesByRecordType()).reduce(
    (acc, [recordType, categoriesSet]) => {
      acc[recordType] = Array.from(categoriesSet);
      return acc;
    },
    {}
  );

  const handleFilterChange = (filters) => {
    if (!notes?.records) return;

    // Clear selections when filters change
    dispatch(clearSelectedNotes());
    setSelectedSummaries([]);

    // Close any expanded items
    setExpandedNoteId(null);
    setExpandedSummaryId(null);

    let filtered = [...notes.records];

    // Apply record type filter first
    if (filters.recordType) {
      const recordTypeMap = {
        personal: "personal_note",
        child: "child_note",
        home: "home_note",
      };
      const recordTypeToFilter = recordTypeMap[filters.recordType];
      filtered = filtered.filter(
        (note) => note.record_type === recordTypeToFilter
      );
    }

    // Apply entity name filter (Home/Child) if applicable
    if (filters.entityName && filters.entityName !== "none") {
      if (filters.recordType === "home") {
        filtered = filtered.filter(
          (note) => note.home_name === filters.entityName
        );
      } else if (filters.recordType === "child") {
        filtered = filtered.filter(
          (note) => note.child_name === filters.entityName
        );
      }
    }

    // Apply remaining filters
    filtered = filtered.filter((note) => {
      let matchesFilters = true;

      // Date range filter
      if (filters.dateRange !== "all") {
        const dateRange = getDateRange(filters);
        if (dateRange) {
          const noteDate = new Date(note.updated_at);
          matchesFilters =
            matchesFilters &&
            noteDate >= dateRange.start &&
            noteDate <= dateRange.end;
        }
      }

      // Category filter
      if (filters.category !== "all") {
        matchesFilters =
          matchesFilters &&
          note.category.toLowerCase() === filters.category.toLowerCase();
      }

      // Search term filter
      if (filters.searchTerm) {
        const searchLower = filters.searchTerm.toLowerCase();
        matchesFilters =
          matchesFilters && note.user_name?.toLowerCase().includes(searchLower);
      }

      return matchesFilters;
    });

    setFilteredNotes(filtered);
  };

  const getDateRange = (filters) => {
    const now = new Date();
    switch (filters.dateRange) {
      case "today":
        return {
          start: new Date(now.setHours(0, 0, 0, 0)),
          end: new Date(now.setHours(23, 59, 59, 999)),
        };
      case "yesterday":
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        return {
          start: new Date(yesterday.setHours(0, 0, 0, 0)),
          end: new Date(yesterday.setHours(23, 59, 59, 999)),
        };
      case "lastWeek":
        const lastWeek = new Date(now);
        lastWeek.setDate(lastWeek.getDate() - 7);
        return {
          start: lastWeek,
          end: now,
        };
      case "lastMonth":
        const lastMonth = new Date(now);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return {
          start: lastMonth,
          end: now,
        };
      case "custom":
        return {
          start: filters.customStartDate,
          end: filters.customEndDate,
        };
      default:
        return null;
    }
  };

  const fetchSummarizedNotes = async () => {
    setLoadingSummaries(true);
    try {
      const response = await apiClient.get(
        `${API_ENDPOINTS.GET_CUSTOM_SUMMARIES}`
      );
      if (response.custom_summaries) {
        setSummarizedNotes(response.custom_summaries);
      }
    } catch (error) {
      console.error("Failed to fetch summarized notes:", error);
    } finally {
      setLoadingSummaries(false);
    }
  };

  useEffect(() => {
    fetchSummarizedNotes();
  }, []);

  const handleSummarize = async () => {
    if (selectedNotes.length === 0) return;

    setIsSummarizing(true);
    try {
      const queryParams = new URLSearchParams({
        custom_prompt: selectedPredefinedPrompts.length > 0
          ? selectedPredefinedPrompts[0].systemPrompt
          : customPrompt || "undefined",
        custom_prompt_name: selectedPredefinedPrompts.length > 0
          ? selectedPredefinedPrompts[0].title
          : customPrompt || "undefined"
      }).toString();

      const response = await apiClient.post(
        `${API_ENDPOINTS.SUMMARIZE_NOTES}?${queryParams}`,
        selectedNotes.map(String)
      );
      if (response) {
        await fetchSummarizedNotes();
        dispatch(clearSelectedNotes());
        setShowSummarizeModal(false);
        setCustomPrompt("");
        setSelectedPredefinedPrompts([]);
        setActiveTab(1);
      }
    } catch (error) {
      console.error("Failed to summarize notes:", error);
    } finally {
      setIsSummarizing(false);
    }
  };

  const handleCopy = async (text, setCopied) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      // Reset after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const handleDeleteNote = async (recordId) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      setDeletingNoteId(recordId);

      try {
        await apiClient.delete(API_ENDPOINTS.DELETE_RECORD, {
          data: { record_ids: [recordId] },
        });
        // Refresh the notes list
        dispatch(fetchNotes());
        // Refresh summarized notes
        await fetchSummarizedNotes();
      } catch (error) {
        console.error("Failed to delete note:", error);
      } finally {
        setDeletingNoteId(null);
      }
    }
  };

  const handleUpdateSummary = async (recordId) => {
    try {
      await apiClient.put(API_ENDPOINTS.UPDATE_RECORD(recordId), {
        summary: editedSummaryText,
      });

      // Refresh notes after update
      dispatch(fetchNotes());
      setEditingSummary(null);
      setEditedSummaryText("");
    } catch (error) {
      console.error("Failed to update summary:", error);
    }
  };

  const fetchPredefinedPrompts = async () => {
    setLoadingPrompts(true);
    try {
      const selectedRecords =
        notes?.records?.filter((note) => selectedNotes.includes(note.id)) || [];
      const recordType = [
        ...new Set(selectedRecords.map((note) => note.record_type)),
      ];
      const category = [
        ...new Set(selectedRecords.map((note) => note.category)),
      ];

      const queryParams = new URLSearchParams({
        record_type: recordType.join(","),
        category: category.join(","),
      }).toString();

      const response = await apiClient.get(
        `${API_ENDPOINTS.GET_CUSTOM_PROMPTS}?${queryParams}`
      );

      const formattedPrompts = response?.prompts?.map((prompt) => ({
        id: prompt.name,
        title: prompt.name
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        systemPrompt: prompt.prompt[0]?.content || "",
      }));

      setPredefinedPrompts(formattedPrompts);
    } catch (error) {
      console.error("Failed to fetch predefined prompts:", error);
    } finally {
      setLoadingPrompts(false);
    }
  };

  useEffect(() => {
    if (showSummarizeModal) {
      fetchPredefinedPrompts();
    }
  }, [showSummarizeModal]);

  const fetchRecordDetails = async (recordId) => {
    setLoadingRecordDetails(true);
    try {
      // Find the record from the existing notes data
      const record = notes?.records?.find((note) => note.id === recordId);
      if (record) {
        setSelectedRecordDetails(record);
      }
    } catch (error) {
      console.error("Failed to fetch record details:", error);
    } finally {
      setLoadingRecordDetails(false);
    }
  };

  const handleBulkDeleteNote = async () => {
    if (selectedNotes.length === 0) return;

    if (
      window.confirm(
        `Are you sure you want to delete ${selectedNotes.length} record(s)?`
      )
    ) {
      setIsBulkDeleting(true);
      try {
        await apiClient.delete(API_ENDPOINTS.DELETE_RECORD, {
          data: { record_ids: selectedNotes },
        });
        // Reset selection
        dispatch(clearSelectedNotes());
        // Refresh the notes list
        dispatch(fetchNotes());
        // Refresh summarized notes
        fetchSummarizedNotes();
      } catch (error) {
        console.error("Failed to delete notes:", error);
      } finally {
        setIsBulkDeleting(false);
      }
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      // Only select notes from the current filtered view
      const filteredNoteIds = filteredNotes.map(note => note.id);
      dispatch(clearSelectedNotes());
      filteredNoteIds.forEach(id => {
        dispatch(toggleSelectedNote(id));
      });
    } else {
      dispatch(clearSelectedNotes());
    }
  };

  const handleDeleteSummaries = async () => {
    if (selectedSummaries.length === 0) return;

    if (
      window.confirm(
        `Are you sure you want to delete ${selectedSummaries.length} summary record(s)?`
      )
    ) {
      setIsBulkDeletingSummaries(true);
      try {
        await apiClient.delete(API_ENDPOINTS.DELETE_CUSTOM_SUMMARY, {
          data: { custom_summary_ids: selectedSummaries },
        });

        // Reset selection
        setSelectedSummaries([]);
        // Reset deletingSummaryId
        setDeletingSummaryId(null);
        // Refresh the notes list
        dispatch(fetchNotes());
        // Refresh summarized notes
        fetchSummarizedNotes();
      } catch (error) {
        console.error("Failed to delete summaries:", error);
        // Reset deletingSummaryId on error
        setDeletingSummaryId(null);
      } finally {
        setIsBulkDeletingSummaries(false);
      }
    } else {
      // Reset deletingSummaryId if user cancels
      setDeletingSummaryId(null);
    }
  };

  const handleSelectAllSummaries = (checked) => {
    if (checked) {
      // Only select summaries from the current filtered view
      const filteredSummaryIds = filteredSummaries.map(summary => summary.id);
      setSelectedSummaries(filteredSummaryIds);
    } else {
      setSelectedSummaries([]);
    }
  };

  const handleSummarySelection = (summaryId) => {
    setSelectedSummaries((prev) => {
      // If holding Shift/Ctrl/Cmd key, toggle the selection
      if (prev.includes(summaryId)) {
        return prev.filter((id) => id !== summaryId);
      } else {
        return [...prev, summaryId];
      }
    });
  };

  // Add this function to handle row click
  const handleSummaryRowClick = (summaryId) => {
    setExpandedSummaryId(expandedSummaryId === summaryId ? null : summaryId);
  };

  const handleSummaryFilterChange = (filters) => {
    if (!summarizedNotes) return;

    // Clear selections when filters change
    setSelectedSummaries([]);

    // Close any expanded items
    setExpandedSummaryId(null);

    let filtered = [...summarizedNotes];

    // Apply record type filter first
    if (filters.recordType) {
      const recordTypeMap = {
        personal: "personal_note",
        child: "child_note",
        home: "home_note",
      };
      const recordTypeToFilter = recordTypeMap[filters.recordType];
      filtered = filtered.filter((summary) =>
        summary.records.some(
          (record) => record.record_type === recordTypeToFilter
        )
      );
    }

    // Apply date filter
    if (filters.dateRange !== "all") {
      const dateRange = getDateRange(filters);
      if (dateRange) {
        filtered = filtered.filter((summary) => {
          const summaryDate = new Date(summary.updated_at);
          return summaryDate >= dateRange.start && summaryDate <= dateRange.end;
        });
      }
    }

    // Apply category filter
    if (filters.category !== "all") {
      filtered = filtered.filter((summary) =>
        summary.records.some(
          (record) =>
            record.category?.toLowerCase() === filters.category.toLowerCase()
        )
      );
    }

    // Apply search term filter - updated to search by user_name
    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      filtered = filtered.filter((summary) =>
        summary.records.some((record) =>
          record.user_name?.toLowerCase().includes(searchLower)
        )
      );
    }

    setFilteredSummaries(filtered);
  };

  useEffect(() => {
    if (summarizedNotes) {
      // Apply initial filter for personal notes in summaries
      handleSummaryFilterChange(INITIAL_FILTERS);
    }
  }, [summarizedNotes]); // Only run when summaries are loaded

  const handleUpdateGeneratedSummary = async (summaryId) => {
    try {
      await apiClient.put(API_ENDPOINTS.UPDATE_CUSTOM_SUMMARY(summaryId), {
        custom_summary: editedGeneratedSummaryText,
      });

      // Refresh summaries after update
      await fetchSummarizedNotes();
      setEditingGeneratedSummary(null);
      setEditedGeneratedSummaryText("");
    } catch (error) {
      console.error("Failed to update generated summary:", error);
    }
  };

  return (
    <div className="space-y-6">
      <Tab.Group
        selectedIndex={activeTab}
        onChange={(index) => {
          setActiveTab(index);
        }}
      >
        <Tab.List className="flex space-x-1 rounded-lg bg-blue-900/20 p-1 mb-2">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Notes
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Summaries
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">Your Notes</h2>
            </div>

            <div>
              <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
                <FilterPanel
                  onFilterChange={handleFilterChange}
                  availableCategories={categoriesByRecordType}
                  notes={notes?.records || []}
                />

                {/* Loading State */}
                {loading && (
                  <div className="flex justify-center items-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                  </div>
                )}

                {/* No Notes State */}
                {!loading &&
                  (!notes?.records || notes.records.length === 0) && (
                    <div className="text-center py-12">
                      <p className="text-gray-500">Notes not available</p>
                    </div>
                  )}

                {/* Notes List */}
                {!loading && notes?.records?.length > 0 && filteredNotes.length > 0 && (
                  <>
                    <div className="p-4 border-b flex items-center justify-between bg-gray-50">
                      <div className="flex items-center space-x-4">
                        <input
                          type="checkbox"
                          checked={
                            filteredNotes.length > 0 &&
                            filteredNotes.every((note) =>
                              selectedNotes.includes(note.id)
                            )
                          }
                          onChange={(e) => handleSelectAll(e.target.checked)}
                          className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                        />
                        <span className="text-sm text-gray-600">
                          {selectedNotes.length} selected
                        </span>
                      </div>
                      {selectedNotes.length > 0 && (
                        <div className="flex space-x-3">
                          <button
                            onClick={() => setShowSummarizeModal(true)}
                            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          >
                            <DocumentDuplicateIcon className="h-4 w-4 mr-1.5" />
                            Summarize ({selectedNotes.length})
                          </button>
                          <button
                            onClick={handleBulkDeleteNote}
                            disabled={isBulkDeleting}
                            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-75 disabled:cursor-not-allowed"
                          >
                            {isBulkDeleting ? (
                              <>
                                <svg
                                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  />
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  />
                                </svg>
                                Deleting...
                              </>
                            ) : (
                              <>
                                <TrashIcon className="h-4 w-4 mr-1.5" />
                                Delete
                              </>
                            )}
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Show "Notes not available" if filtered data is empty */}
                    {filteredNotes.length === 0 ? (
                      <div className="text-center py-12">
                        <p className="text-gray-500">Notes not available</p>
                      </div>
                    ) : (
                      <ul className="divide-y-0">
                        {filteredNotes.map((note) => (
                          <li
                            key={note.id}
                            onClick={() =>
                              setExpandedNoteId(
                                expandedNoteId === note.id ? null : note.id
                              )
                            }
                            className={`border-l-4 transition-colors duration-200 cursor-pointer ${expandedNoteId === note.id
                              ? "border-l-primary-600 bg-gray-50"
                              : "border-l-transparent hover:bg-gray-50"
                              } ${selectedNotes.includes(note.id)
                                ? "bg-primary-50"
                                : ""
                              }`}
                          >
                            <div className="px-4 py-4 sm:px-6">
                              <ListItemHeader
                                isSelected={selectedNotes.includes(note.id)}
                                onCheckboxChange={() =>
                                  dispatch(toggleSelectedNote(note.id))
                                }
                                title={`${note.record_type
                                  .split("_")[0]
                                  .charAt(0)
                                  .toUpperCase() +
                                  note.record_type.split("_")[0].slice(1)
                                  } Note`}
                                subtitle={`Record ID: ${note.record_id_int}`}
                                timestamp={new Date(
                                  note.updated_at
                                ).toLocaleDateString()}
                                userName={note.user_name}
                                backendPromptName={note.backend_prompt_name}
                                customPromptName={null}
                                onDelete={() => handleDeleteNote(note.id)}
                                isDeleting={deletingNoteId === note.id}
                                isExpanded={expandedNoteId === note.id}
                              />

                              {/* Preview when not expanded */}
                              {expandedNoteId !== note.id && (
                                <div className="mt-2">
                                  <p className="text-sm text-gray-600 line-clamp-2">
                                    {note.summary || "AI Summary not available"}
                                  </p>
                                </div>
                              )}
                            </div>

                            {/* Expanded View */}
                            {expandedNoteId === note.id && (
                              <div className="mx-4 mb-4 bg-gray-50">
                                <div className="px-6 py-5 space-y-4">
                                  {/* Audio Section */}
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <h4 className="text-sm font-medium text-gray-900 mb-3">
                                      Audio Recording
                                    </h4>
                                    <AudioPlayer recordId={note.id} />
                                  </div>

                                  {/* AI Summary Section */}
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <div className="flex justify-between items-center mb-3">
                                      <h4 className="text-sm font-medium text-gray-900">
                                        AI Summary
                                      </h4>
                                      <div className="flex items-center space-x-2">
                                        {editingSummary === note.id ? (
                                          <>
                                            <button
                                              onClick={() =>
                                                handleUpdateSummary(note.id)
                                              }
                                              className="inline-flex items-center text-sm text-green-600 hover:text-green-700 px-2 py-1 rounded-md hover:bg-green-50"
                                            >
                                              <CheckIcon className="h-4 w-4 mr-1" />
                                              Save
                                            </button>
                                            <button
                                              onClick={() => {
                                                setEditingSummary(null);
                                                setEditedSummaryText("");
                                              }}
                                              className="inline-flex items-center text-sm text-red-600 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50"
                                            >
                                              <XMarkIcon className="h-4 w-4 mr-1" />
                                              Cancel
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              onClick={() => {
                                                setEditingSummary(note.id);
                                                setEditedSummaryText(
                                                  note.summary || ""
                                                );
                                              }}
                                              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                            >
                                              <PencilIcon className="h-4 w-4 mr-1" />
                                              Edit
                                            </button>
                                            {note.summary && (
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleCopy(
                                                    note.summary,
                                                    setCopiedSummary
                                                  );
                                                }}
                                                className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                              >
                                                <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                                {copiedSummary
                                                  ? "Copied"
                                                  : "Copy"}
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="bg-primary-50 rounded-md p-4">
                                      {editingSummary === note.id ? (
                                        <textarea
                                          value={editedSummaryText}
                                          onChange={(e) =>
                                            setEditedSummaryText(e.target.value)
                                          }
                                          className="w-full min-h-[100px] p-2 text-sm text-gray-600 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                                          placeholder="Enter summary..."
                                        />
                                      ) : (
                                        <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                          {note.summary ||
                                            "AI Summary not available"}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  {/* Transcript Section */}
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <div className="flex justify-between items-center mb-3">
                                      <h4 className="text-sm font-medium text-gray-900">
                                        Transcript
                                      </h4>
                                      {note.transcript && (
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopy(
                                              note.transcript,
                                              setCopiedTranscript
                                            );
                                          }}
                                          className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                        >
                                          <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                          {copiedTranscript ? "Copied" : "Copy"}
                                        </button>
                                      )}
                                    </div>
                                    <div className="bg-gray-50 rounded-md p-4">
                                      <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                        {note.transcript ||
                                          "Transcript not available"}
                                      </p>
                                    </div>
                                  </div>

                                  {/* Additional Notes Section */}
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <div className="flex justify-between items-center mb-3">
                                      <h4 className="text-sm font-medium text-gray-900">
                                        Additional Notes
                                      </h4>
                                      {note.additional_notes && (
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopy(
                                              note.additional_notes,
                                              setCopiedAdditionalNotes
                                            );
                                          }}
                                          className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                        >
                                          <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                          {copiedAdditionalNotes
                                            ? "Copied"
                                            : "Copy"}
                                        </button>
                                      )}
                                    </div>
                                    <div className="bg-gray-50 rounded-md p-4">
                                      <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                        {note.additional_notes ||
                                          "No additional notes"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">
                Your Summaries
              </h2>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
              <FilterPanel
                onFilterChange={handleSummaryFilterChange}
                availableCategories={categoriesByRecordType}
                notes={notes?.records || []}
              />

              {/* Loading State */}
              {loadingSummaries && (
                <div className="flex justify-center items-center py-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                </div>
              )}

              {/* No Summaries State */}
              {!loadingSummaries &&
                (!summarizedNotes || summarizedNotes.length === 0) && (
                  <div className="text-center py-12">
                    <p className="text-gray-500">No summaries found</p>
                  </div>
                )}

              {/* Summaries List */}
              {!loadingSummaries && summarizedNotes.length > 0 && filteredSummaries.length > 0 && (
                <>
                  <div className="p-4 border-b flex items-center justify-between bg-gray-50">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        checked={
                          filteredSummaries.length > 0 &&
                          filteredSummaries.every((summary) =>
                            selectedSummaries.includes(summary.id)
                          )
                        }
                        onChange={(e) => handleSelectAllSummaries(e.target.checked)}
                        className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                      />
                      <span className="text-sm text-gray-600">
                        {selectedSummaries.length} selected
                      </span>
                    </div>
                    {selectedSummaries.length > 0 && (
                      <button
                        onClick={handleDeleteSummaries}
                        disabled={isBulkDeletingSummaries}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-75 disabled:cursor-not-allowed"
                      >
                        {isBulkDeletingSummaries ? (
                          <>
                            <svg
                              className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                            Deleting...
                          </>
                        ) : (
                          <>
                            <TrashIcon className="h-4 w-4 mr-1.5" />
                            Delete
                          </>
                        )}
                      </button>
                    )}
                  </div>

                  {/* Show "Notes not available" if filtered data is empty */}
                  {filteredSummaries.length === 0 ? (
                    <div className="text-center py-12">
                      <p className="text-gray-500">Notes not available</p>
                    </div>
                  ) : (
                    <ul className="divide-y-0">
                      {filteredSummaries.map((summary) => (
                        <li
                          key={summary.id}
                          onClick={() =>
                            handleSummaryRowClick(summary.id)
                          }
                          className={`border-l-4 transition-colors duration-200 cursor-pointer ${expandedSummaryId === summary.id
                            ? "border-l-primary-600 bg-gray-50"
                            : "border-l-transparent hover:bg-gray-50"
                            } ${selectedSummaries.includes(
                              summary.id
                            )
                              ? "bg-primary-50"
                              : ""
                            }`}
                        >
                          <div className="px-4 py-4 sm:px-6">
                            <ListItemHeader
                              isSelected={selectedSummaries.includes(
                                summary.id
                              )}
                              onCheckboxChange={() =>
                                handleSummarySelection(
                                  summary.id
                                )
                              }
                              title={`${summary.records.length} Notes Summary${summary.records[0]?.record_type === 'home_note' && summary.records[0]?.home_name
                                ? ` - ${summary.records[0].home_name}`
                                : summary.records[0]?.record_type === 'child_note' && summary.records[0]?.child_name
                                  ? ` - ${summary.records[0].child_name}`
                                  : ''
                                }`}
                              timestamp={new Date(
                                summary.updated_at
                              ).toLocaleDateString()}
                              userName={summary.user_name}
                              customPromptName={summary.custom_prompt_name}
                              backendPromptName={null}
                              onDelete={() => {
                                setSelectedSummaries([
                                  summary.id,
                                ]);
                                setDeletingSummaryId(summary.id);
                                handleDeleteSummaries();
                              }}
                              isDeleting={
                                deletingSummaryId === summary.id
                              }
                              isExpanded={
                                expandedSummaryId === summary.id
                              }
                            />

                            {/* Preview when not expanded */}
                            {expandedSummaryId !==
                              summary.id && (
                                <div className="mt-2">
                                  <p className="text-sm text-gray-600 line-clamp-2">
                                    {summary.summary || "Summary not available"}
                                  </p>
                                </div>
                              )}

                            {/* Expanded View */}
                            {expandedSummaryId ===
                              summary.id && (
                                <div className="mt-4 space-y-4">
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <div className="flex justify-between items-center mb-3">
                                      <h4 className="text-sm font-medium text-gray-900">
                                        Generated Summary
                                      </h4>
                                      <div className="flex items-center space-x-2">
                                        {editingGeneratedSummary === summary.id ? (
                                          <>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleUpdateGeneratedSummary(summary.id);
                                              }}
                                              className="inline-flex items-center text-sm text-green-600 hover:text-green-700 px-2 py-1 rounded-md hover:bg-green-50"
                                            >
                                              <CheckIcon className="h-4 w-4 mr-1" />
                                              Save
                                            </button>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingGeneratedSummary(null);
                                                setEditedGeneratedSummaryText("");
                                              }}
                                              className="inline-flex items-center text-sm text-red-600 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50"
                                            >
                                              <XMarkIcon className="h-4 w-4 mr-1" />
                                              Cancel
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingGeneratedSummary(summary.id);
                                                setEditedGeneratedSummaryText(summary.summary || "");
                                              }}
                                              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                            >
                                              <PencilIcon className="h-4 w-4 mr-1" />
                                              Edit
                                            </button>
                                            {summary.summary && (
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleCopy(summary.summary, setCopiedGeneratedSummary);
                                                }}
                                                className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                              >
                                                <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                                {copiedGeneratedSummary ? "Copied" : "Copy"}
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="bg-primary-50 rounded-md p-4">
                                      {editingGeneratedSummary === summary.id ? (
                                        <textarea
                                          value={editedGeneratedSummaryText}
                                          onChange={(e) => setEditedGeneratedSummaryText(e.target.value)}
                                          onClick={(e) => e.stopPropagation()}
                                          className="w-full min-h-[100px] p-2 text-sm text-gray-600 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                                          placeholder="Enter summary..."
                                        />
                                      ) : (
                                        <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                          {summary.summary || "Summary not available"}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <h4 className="text-sm font-medium text-gray-900 mb-3">
                                      Included Notes
                                    </h4>
                                    <div className="space-y-2">
                                      {summary.records.map((record) => (
                                        <div
                                          key={record.id}
                                          className="bg-gray-50 rounded-md p-4 cursor-pointer hover:bg-gray-100"
                                          onClick={() =>
                                            fetchRecordDetails(record.id)
                                          }
                                        >
                                          <div className="flex justify-between">
                                            <div>
                                              <p className="text-sm font-medium text-gray-900">
                                                Record ID: {record.record_id_int}
                                              </p>
                                              <p className="text-sm text-gray-500">
                                                {record.category
                                                  ?.replace(/_/g, " ")
                                                  .replace(/\b\w/g, (l) =>
                                                    l.toUpperCase()
                                                  )}
                                              </p>
                                            </div>
                                            <span className="text-sm text-gray-500">
                                              {new Date(
                                                record.updated_at
                                              ).toLocaleDateString()}
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* Summarize Modal */}
      {showSummarizeModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Summarize {selectedNotes.length} Note(s)
              </h3>
              <button
                onClick={() => setShowSummarizeModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 tracking-wide uppercase mb-2">
                  Selected Notes
                </label>
                <div className="mt-2 max-h-40 overflow-y-auto">
                  {notes?.records
                    ?.filter((note) => selectedNotes.includes(note.id))
                    ?.map((note) => (
                      <div key={note.id} className="flex items-center py-2">
                        <DocumentTextIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <span className="text-sm text-gray-600">
                          {note.category
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())}{" "}
                          -{new Date(note.updated_at).toLocaleDateString()}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 tracking-wide uppercase mb-2">
                  Custom Instructions
                </label>
                <div className="space-y-4">
                  <div className="space-y-2">
                    {loadingPrompts ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
                      </div>
                    ) : predefinedPrompts.length > 0 ? (
                      <RadioGroup
                        value={selectedPredefinedPrompts[0] || null}
                        onChange={(value) =>
                          setSelectedPredefinedPrompts(value ? [value] : [])
                        }
                      >
                        <div className="space-y-2">
                          {predefinedPrompts.map((prompt) => (
                            <RadioGroup.Option
                              key={prompt.id}
                              value={prompt}
                              className={({ checked }) => `
                                relative flex cursor-pointer rounded-lg border p-4 focus:outline-none
                                ${checked
                                  ? "bg-primary-50 border-primary-500 text-primary-700"
                                  : "bg-white border-gray-300 hover:bg-gray-50"
                                }
                              `}
                            >
                              {({ checked }) => (
                                <div className="flex w-full items-center justify-between">
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label
                                        className={`font-medium ${checked
                                          ? "text-primary-700"
                                          : "text-gray-900"
                                          }`}
                                      >
                                        {prompt.title}
                                      </RadioGroup.Label>
                                    </div>
                                  </div>
                                  <div
                                    className={`shrink-0 ${checked
                                      ? "text-primary-600"
                                      : "text-gray-400"
                                      }`}
                                  >
                                    <div
                                      className={`
                                      h-4 w-4 rounded-full border flex items-center justify-center
                                      ${checked
                                          ? "border-primary-600 bg-primary-600"
                                          : "border-gray-300"
                                        }
                                    `}
                                    >
                                      <div
                                        className={`
                                        h-2 w-2 rounded-full bg-white
                                        ${checked ? "opacity-100" : "opacity-0"}
                                      `}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    ) : (
                      <div className="text-sm text-gray-500 italic p-4 bg-gray-50 rounded-lg">
                        No predefined instructions available for these notes
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <p className="text-sm text-gray-500 mb-2">
                      {predefinedPrompts.length > 0 ? "Or write" : "Write"} your
                      own custom instructions:
                    </p>
                    <textarea
                      value={customPrompt}
                      onChange={(e) => {
                        setCustomPrompt(e.target.value);
                        setSelectedPredefinedPrompts([]);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      rows={4}
                      placeholder="E.g., Summarize the child's behavior patterns across these observations..."
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowSummarizeModal(false)}
                  disabled={isSummarizing}
                  className={`px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md ${isSummarizing ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSummarize}
                  disabled={isSummarizing}
                  className={`px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md inline-flex items-center justify-center min-w-[140px] ${isSummarizing
                    ? "opacity-75 cursor-not-allowed"
                    : "hover:bg-primary-700"
                    }`}
                >
                  {isSummarizing ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Generating...
                    </>
                  ) : (
                    <>
                      <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
                      Generate Summary
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add this new Record Details Modal */}
      {selectedRecordDetails && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Record Details{" "}
                <span className="text-gray-500">
                  ({selectedRecordDetails.record_id_int})
                </span>
              </h3>
              <button
                onClick={() => setSelectedRecordDetails(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {loadingRecordDetails ? (
              <div className="flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
              </div>
            ) : (
              <div className="space-y-4">
                {/* Record Type and Category */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Record Type
                      </p>
                      <p className="text-sm text-gray-900">
                        {selectedRecordDetails.record_type
                          ?.split("_")[0]
                          .charAt(0)
                          .toUpperCase() +
                          selectedRecordDetails.record_type
                            ?.split("_")[0]
                            .slice(1)}{" "}
                        Note
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Category
                      </p>
                      <p className="text-sm text-gray-900">
                        {selectedRecordDetails.category
                          ?.replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Audio Player */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    Audio Recording
                  </h4>
                  <AudioPlayer recordId={selectedRecordDetails.id} />
                </div>

                {/* AI Summary */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    AI Summary
                  </h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.summary || "Summary not available"}
                  </p>
                </div>

                {/* Transcript */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    Transcript
                  </h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.transcript ||
                      "Transcript not available"}
                  </p>
                </div>

                {/* Additional Notes */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    Additional Notes
                  </h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.additional_notes ||
                      "No additional notes"}
                  </p>
                </div>

                {/* Metadata */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Updated Date
                    </p>
                    <p className="text-sm text-gray-900">
                      {new Date(
                        selectedRecordDetails.updated_at
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NotesView;
