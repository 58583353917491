import { store } from '../store/store';
import { clearAudioState } from '../store/slices/audioSlice';
import { apiClient, API_ENDPOINTS } from '../api/apiConfig';

export class AudioService {
  constructor() {
    this.websocket = null;
    this.audioContext = null;
    this.audioSource = null;
    this.audioProcessor = null;
    this.stream = null;
    this.conversationId = null;
  }

  async setConversationId(conversationId) {
    this.conversationId = conversationId;
  }

  async startRecording(websocketUrl) {
    try {
      this.websocket = new WebSocket(websocketUrl);
      await new Promise((resolve, reject) => {
        this.websocket.onopen = () => resolve();
        this.websocket.onerror = (error) => reject(error);
      });

      this.audioContext = new window.AudioContext();
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 48000,
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          latencyHint: 'interactive'
        }
      });
      this.audioSource = this.audioContext.createMediaStreamSource(this.stream);
      this.audioProcessor = this.audioContext.createScriptProcessor(4096, 1, 1);

      this.audioProcessor.onaudioprocess = (e) => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          const float32Array = e.inputBuffer.getChannelData(0);
          const int16Array = new Int16Array(float32Array.length);
          for (let i = 0; i < float32Array.length; i++) {
            int16Array[i] = Math.max(-32768, Math.min(32767, Math.floor(float32Array[i] * 32768)));
          }
          this.websocket.send(int16Array.buffer);
        }
      };

      this.audioSource.connect(this.audioProcessor);
      this.audioProcessor.connect(this.audioContext.destination);
    } catch (error) {
      console.error("Error starting recording:", error);
      throw error;
    }
  }

  async endConversation(conversationId) {
    await apiClient.post(`${API_ENDPOINTS.END_CONVERSATION}/${conversationId}`);
  }

  async stopRecording() {
    try {
      if (this.audioProcessor) {
        this.audioProcessor.disconnect();
        this.audioSource.disconnect();
        this.audioProcessor = null;
        this.audioSource = null;
      }

      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }

      if (this.websocket) {
        this.websocket.close();
        this.websocket = null;
      }
      if (this.conversationId) {
        await this.endConversation(this.conversationId);
        this.conversationId = null;
      }

      store.dispatch(clearAudioState());
    } catch (error) {
      console.error("Error stopping recording:", error);
      throw error;
    }
  }

  getStream() {
    return this.stream;
  }

}

export const audioService = new AudioService();