import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AccountSection from '../components/settings/AccountSection';
import SessionSection from '../components/settings/SessionSection';
import CustomPromptsSection from '../components/settings/CustomPromptsSection';
import CategorySection from '../components/settings/CategorySection';
import BackendPromptsSection from '../components/settings/BackendPromptsSection';

export const SettingsSection = ({ title, icon: Icon, children }) => (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
        <div className="flex items-center mb-4">
            <Icon className="h-6 w-6 text-primary-600 mr-2" />
            <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        </div>
        {children}
    </div>
);

const Settings = () => {
    const location = useLocation();
    const categorySectionRef = useRef(null);
    const backendPromptsRef = useRef(null);

    useEffect(() => {
        if (location.state?.section === 'categories' && categorySectionRef.current) {
            categorySectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (location.state?.section === 'backend-prompts' && backendPromptsRef.current) {
            backendPromptsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.state]);

    return (
        <div className="max-w-4xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">Echo Settings</h1>
            <AccountSection />
            <SessionSection />
            <div ref={categorySectionRef}>
                <CategorySection />
            </div>
            <div ref={backendPromptsRef}>
                <BackendPromptsSection />
            </div>
            <CustomPromptsSection />
        </div>
    );
};

export default Settings; 