import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNotes } from "../store/slices/notesSlice";

function Home() {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.notes);
  const loading = useSelector((state) => state.notes.loading);
  const [recordTypeFilter, setRecordTypeFilter] = useState("all");
  const [categoryFilter, setCategoryFilter] = useState("all");

  useEffect(() => {
    dispatch(fetchNotes());
  }, [dispatch]);


  const filteredNotes = notes?.records?.filter((note) => {
    const matchesRecordType = recordTypeFilter === "all" || note.record_type === recordTypeFilter;
    const matchesCategory = categoryFilter === "all" || note.category === categoryFilter;
    return matchesRecordType && matchesCategory;
  }) || [];

  const uniqueCategories = [...new Set(notes?.records?.map(note => note.category) || [])];

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-2xl font-bold text-gray-900">
          Last 48 Hours Overview
        </h2>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full sm:w-auto">
          <select
            value={recordTypeFilter}
            onChange={(e) => setRecordTypeFilter(e.target.value)}
            className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
          >
            <option value="all">All Note Types</option>
            <option value="personal_note">Personal Notes</option>
            <option value="child_note">Child Notes</option>
            <option value="home_note">Home Notes</option>
          </select>

          <select
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
            className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
          >
            <option value="all">All Categories</option>
            {uniqueCategories.map(category => (
              <option key={category} value={category}>
                {category.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading && (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
        </div>
      )}

      {!loading && (!notes?.records || notes.records.length === 0 || !filteredNotes || filteredNotes.length === 0) && (
        <div className="text-center py-12 bg-white shadow overflow-hidden sm:rounded-md">
          <p className="text-gray-500">Notes not available</p>
        </div>
      )}

      {!loading && notes?.records?.length > 0 && (
        <div className="mt-8">
          <div className="mt-2 overflow-hidden bg-white shadow sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {filteredNotes
                ?.sort(
                  (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                )
                ?.map((note) => (
                  <li key={note.id}>
                    <div className="px-4 py-4 sm:px-6 hover:bg-gray-50">
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <p className="truncate text-md font-medium text-primary-600">
                            {note.record_type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                          </p>
                          <p className="truncate text-sm font-medium text-secondary-600">
                            {note.category.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                          </p>
                          <p className="text-xs text-gray-500 mt-1">
                            Record ID: {note.record_id_int}
                          </p>
                        </div>
                        <div className="w-1/5 text-left">
                          <p className="text-sm text-gray-500">
                            {new Date(note?.updated_at)?.toLocaleDateString()}
                          </p>
                          <p className="text-sm text-gray-500 mt-1">
                            Submitted By: {note?.user_name}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 flex justify-between">
                        <div className="sm:flex w-4/5">
                          <p className="text-sm text-gray-500">
                            {note?.summary || "AI Summary not available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
