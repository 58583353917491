import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./auth/msalConfig";
import { store } from "./store/store";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./auth/AuthContext";
import { initHotjar } from "./utils/hotjar";
import Settings from "./pages/Settings";
import RecordChildPage from './pages/RecordChildPage';
import RecordHomePage from './pages/RecordHomePage';
import RecordPersonalPage from './pages/RecordPersonalPage';
import RecordsHomePage from './pages/RecordsHomePage';
import NotesViewPage from './pages/NotesViewPage';

function App() {
  useEffect(() => {
    initHotjar();
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/home"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<Home />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/notes"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<NotesViewPage />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record-page"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordsHomePage />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record/child"
                element={
                  <Layout>
                    <ProtectedRoute element={<RecordChildPage />} />
                  </Layout>
                }
              />
              <Route
                path="/record/home"
                element={
                  <Layout>
                    <ProtectedRoute element={<RecordHomePage />} />
                  </Layout>
                }
              />
              <Route
                path="/record/personal"
                element={
                  <Layout>
                    <ProtectedRoute element={<RecordPersonalPage />} />
                  </Layout>
                }
              />
              <Route
                path="/settings"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<Settings />} />
                    </Layout>
                  </>
                }
              />
            </Routes>
          </Router>
        </Provider>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
